<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="config. bem vindo" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="card card-bg-trans">
                        <div class="card-body pt-4 px-0">

                            <form class="px-2 needs-validation" novalidate @submit.prevent="save">
                                <b-tabs>
                                    <b-tab class="tab-pane" id="commercial">
                                        <template #title>
                                            <div class="d-flex">
                                                <span class="d-none d-lg-block">Etapa 1</span>
                                            </div>
                                        </template>

                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <vue-editor v-model="welcome.step_1" style="background: #fff"></vue-editor>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <b-tab class="tab-pane" id="terms">
                                        <template #title>
                                            <div class="d-flex">
                                                <span class="d-none d-lg-block">Etapa 2</span>
                                            </div>
                                        </template>
                                            
                                        <div class="form-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Mensagem</label>
                                                        <vue-editor v-model="welcome.step_2" style="background: #fff"></vue-editor>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </b-tab>

                                    <b-tab class="tab-pane" id="privacy">
                                        <template #title>
                                            <div class="d-flex">
                                                <span class="d-none d-lg-block">Etapa 3</span>
                                            </div>
                                        </template>

                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label>Mensagem</label>
                                                            <vue-editor v-model="welcome.step_3" style="background: #fff"></vue-editor>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                    <b-tab class="tab-pane" id="privacy">
                                        <template #title>
                                            <div class="d-flex">
                                                <span class="d-none d-lg-block">Config. bem vindo</span>
                                            </div>
                                        </template>

                                            <div class="form-body">
                                                <div class="row">
                                                    <div class="col-12 col-md-3">
                                                        <div class="form-group">
                                                           <label>Status </label>
                                                            <select class="custom-select mr-sm-2" v-model="welcome.is_active">
                                                                <option :value="true">Ativo</option>
                                                                <option :value="false">Inativo</option>
                                                            </select>
                                                        </div>
                                                            <small id="name13" class="badge badge-default badge-danger form-text text-white">Desativar página seja bem vindo</small>
                                                    </div>
                                                </div>
                                            </div>
                                    </b-tab>
                                </b-tabs>
                                <div class="form-actions">
                                    <div class="text-right">
                                        <button type="submit" class="btn btn-info">Salvar</button>
                                    </div>
                                </div>
                            </form>



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import WelcomeService from "@/services/resources/WelcomeService";
const service = WelcomeService.build();
import { VueEditor } from "vue2-editor";

export default {
  components:{
    VueEditor
  },
  data() {
    return {
      welcome: {
          id: 0,
          step_1: null,
          step_2: null,
          step_3: null,
          is_active: true
      },
      pages:[
            {
                name: 'dashboard',
                to: 'Dashboard'
            },
            {
                name: 'config. bem vindo',
                to: 'ConfigsWelcome'
           }
      ],
      menu:[],
    };
  },
  methods: {
    save(){
      this.$validator.validateAll().then((result) => {
        if (result) {

            service
            .update(this.welcome)
            .then(resp => {
                 this.$bvToast.toast('Atualizado com sucesso!', {
                    title: 'Bem vindo',
                    autoHideDelay: 5000,
                    type: 'success'
                })
            })
            .catch(err => {
                console.log(err)
            })
          }
      });
    },
    fetch(){
        
        service
        .search()
        .then(resp => {
            if(resp){
                this.welcome = resp;
                this.welcome.is_active = resp.is_active == 1
            }
        })
        .catch(err => {
            console.log(err)
        })
    }

  },
  mounted() {
      this.fetch();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>